import React, { useRef, useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";


import "./ContactUs.css";
import axios from "axios";

const ContactUs = () => {
    const URL = "https://api.guardefy.com/sendmsg.php";
    const KEY = 'g8hSQFXDb5bgzuHJ';

    const name = useRef();
    const contacts = useRef();
    const message = useRef();

    const [errorShow, setErrorShow] = useState(false);

    const [successMessage, setSuccessMessage] = useState('Please fill out the form above to contact us.All fields are required.');

    const handleCloseError = () => {
        setErrorShow(false);
    };
    const handleOpenError = () => {
        setErrorShow(true);
    };

    const submitButtonHandler = () => {
        if (
            name.current.value &&
            contacts.current.value &&
            message.current.value
        ) {
            saveMessageToDatabase(
                name.current.value,
                contacts.current.value,
                message.current.value
            );
            name.current.value = "";
            contacts.current.value = "";
            message.current.value = "";
        } else {
            handleOpenError();
        }
    };

    const saveMessageToDatabase = async (name, contacts, message) => {
        const msg = {
            code: KEY,
            name: name,
            contacts: contacts,
            message: message,
        };
        const data = await axios.post(
            URL,
            new URLSearchParams(msg).toString()
        );

        if(data.data.isSuccess) {
            setSuccessMessage("The request is submitted successfully! We'll get back to you shortly.");
        }
    };

    return (
        <div className="contact_container" id="ContactUs">
            <h1>Don't know where to start? Drop us a message</h1>
            <div className="form_container">
                <Form>
                    <div className="form_contact_info_container">
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Control
                                type="text"
                                ref={name}
                                placeholder="Your name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="text"
                                ref={contacts}
                                placeholder="Enter email or telegram"
                            />
                            <Form.Text className="text-muted">
                                We never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Control
                            as="textarea"
                            ref={message}
                            rows={4}
                            style={{ resize: "none" }}
                            placeholder="Enter your message"
                        />
                        <Form.Text className="text-muted">
                            <Form.Text className="text-muted">
                                {successMessage}
                            </Form.Text>
                        </Form.Text>
                    </Form.Group>
                    <Button
                        variant="outline-dark"
                        onClick={submitButtonHandler}
                    >
                        Submit
                    </Button>
                </Form>
            </div>

            <Modal show={errorShow}>
                <Modal.Header>
                    <Modal.Title>Form filling error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please fill all required fields and re-submit form.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseError}>
                        Got it!
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ContactUs;
