import React from "react";
import "./SecurityAudits.css";
import aboutImage from "../../../img/about_image.png";

const SecurityAudits = () => {
    return (
        <div className="audits_container" id="SecurityAudits">
            <div className="text">
                <h1>Blockchain security audits<b className="cursor">_</b></h1>
                <p>Secure your blockchain project with our expert smart contract audits. Our top-notch team will meticulously examine your smart contracts, identifying and eliminating potential vulnerabilities. Gain peace of mind knowing that your project is fortified against risks. Protect your investments, build trust, and ensure compliance with our comprehensive audit services.</p>
            </div>
            <div className="image_container">
                <img src={aboutImage} alt="About Image" className="image"/>
            </div>
        </div>
    );
};

export default SecurityAudits;
