import './App.css';

import Navigation from './components/Navigation/Navigation';
import SecurityAudits from './components/Content/SecurityAudits/SecurityAudits';
import About from './components/Content/About/About';
import ContactUs from './components/Content/OurClients/ContactUs';
import Footer from './components/Content/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Navigation />
      <SecurityAudits />
      {/* <About /> */}
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
